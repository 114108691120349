import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';





// Components
import {Header} from './header.js';
import {Summary} from './summary.js';
import BasicContent from './basicContent.js';
import ContentType1 from './contentType1.js';
import ContenType2 from './contentType2.js';
import ContentType3 from './contentType3.js';
import TitleBar from './titleBar.js';
import Gallery from 'react-grid-gallery';
import ImgCarousel from './carousel.js';
import LightBoxComponent from './imageLightBox.js';

// IMAGES
import CS3_1 from './images/CS3 — 8.PNG';
import CS3_1_1 from './images/CS3 — 8.1.PNG';
import CS3_2 from './images/CS3 -- 1.jpg';
import CS3_3 from './images/CS3 -- 2.jpg';
import CS3_4 from './images/CS3 — 3.PNG';
import CS3_5 from './images/CS3 -- 4.jpg';
import CS3_6 from './images/CS3 -- 5.jpg';
import CS3_7 from './images/CS3 — 6.PNG';
import CS3_8 from './images/CS3 -- 7.JPG';


const array = [{src:CS3_1, alt:'UX Priorities', video:"false"},
               {src:CS3_1_1, alt:'UX Priorities', video:"false"},
               {src:CS3_2, alt:'Problem Definition', video:"false"},
               {src:CS3_3, alt:'User Persona', video:"false"},
               {src:CS3_4, alt:'List of What We Know', video:"false"},
               {src:CS3_5, alt:'List of What We Know', video:"false"},
               {src:CS3_6, alt:'Field interview with someone', video:"false"},
               {src:CS3_7, alt:'5 Whys Brainstorm', video:"false"},
               {src:CS3_8, alt:'Experiment Canvas', video:"false"},

              ];









// Secondary Components

const SurveyData = ({title,
                     block,
                     number1,
                     number2,
                     number3,
                     number4,
                     description1,
                     description2,
                     description3,
                     description4}) =>{

  const surveyData = [
    {number: number1,
     description: description1},

    {number: number2,
     description: description2},

    {number: number3,
     description: description3},

    {number: number4,
     description: description4},
  ];

  return(
    <section className={block}>
      <section className="surveyDataTitle">
        <h1>{title}</h1>
      </section>
      <section className="surveyDataContainer">
        {surveyData.map((i)=>{
          return <section className="surveyData">
            <h1>{i.number}</h1>
            <p>{i.description}</p>
          </section>
        })}
      </section>
    </section>
  )
}


const internalSurvey = <p>As the lead UX Designer on the project, I collaborated with the Product Leads, Director UX / UI, and the other UX Designer on my team to conduct research, iterate, and pitch a brand new solution.  During the implementation phase, I worked closely with the Development Leads to ensure usability of the feature.<br/> The trend trend from this survey was: Becoming easer to use, more clear, more flexible, and transparent would help bring clients more value.</p>

const groupInterview = <p>To help balance out the survey results, we held an internal group interview with many of the same participants in order to gather more qualitative data. <br/> We learned that:
                         <ul>
                            <li> The vast majority clients are notproperly set up to scale with us and that hurts our value proposition. We then cannot use the full breadth of our technology</li>
                            <li>Any Solution we build to help create better content will only be useful if the clients can use it.</li>
                          </ul>
                        </p>

const userProfile = <p>
                      As a client:
                      <ul>
                        <li>I don't want to learn another tool</li>
                        <li>I don't want to log into an extra platform</li>
                        <li>I want to spend the least amount of time in your application as possible because I'm already busy.</li>
                        <li>I think, with the volume of creatives, your application seems like a lot of work.</li>
                        <li>I just want to see what tasks I need to complete so we can keep on target.</li>
                        <li>We keep track of all our campaigns internally.</li>
                        <li>I use your application mostly to request campaigns and approve them.</li>
                        <li>In your application, I don't know where to find things that I know exist.</li>
                      </ul>
                    </p>

const flexibilityProblems = <p>Too much flexibility had hindered our ability to be clear about what actions need to happen.
                              <ul>
                                <li>It changes for every account and can change per the type of campaign.</li>
                                <li>It causes confusion with how we speak to clients</li>
                                <li>It cause cognitive overload.
                                </li>
                                <li>It causes a lack of funnels within the application.</li>
                                <li>It causes the need for an infinite number of “small” additions to the current setup.
                                </li>
                                <li>Each account team has a different way of providing data.
                                </li>
                              </ul>

                            </p>

const quote1 = <aside><p>“Clients have a hard time finding things they know exist"</p></aside>

// Actual Component


class CS3Content extends Component {

constructor(props){
  super(props);


}




componentDidMount(){
  document.addEventListener('scroll', this.scrollFunction)
  console.log(this.headerTitle)
}

componentWillUnmount(){
  document.removeEventListener('scroll', this.scrollFunction)
}

  render() {

    const style = {
      marginBottom:64,
    }

    return(
      <article class="CS3_Content1">

          <Summary who={this.props.summary.who}
                   what={this.props.summary.what}
                   result={this.props.summary.result}
                   resultDescription={this.props.summary.resultDescription}

          />

          <ContenType2 sectionTitle="Introduction"
                       sectionNumber="02"
                       titleBarId="introduction"
                       contentTitle1="My Role"
                       content1="As the UX Design lead, I collaborated with the Product lead in defining the problem, pitching the solution, and outlining the research plan.  I then worked jointly with another designer and the Product Lead to conduct research, synthesize the findings, and iterate on our direction before beginning cooperative work with development."
                       contentTitle2="Background"
                       content2="In the fall of 2020, during the process of defining the 2021 roadmap, I conducted an internal survey to understand whether our 2021 roadmap aligned with the key problems of our customers and internal users. As the survey was running, I also conducted a group interview on the topic during our Feedback Advisory Board (FAB) session. The results of this session and the survey, led us in the discovery of some bigger problem spaces and ultimately some newer solutions; one being this project."
                       block="contentBlock1 contentType2"
          />



          <section style={style} class="contentBlock2">
            <TitleBar sectionTitle="Outlining the Problem Space"/>

            <ContentType1 title="UX Prioritization"
                           content="The qualitative feedback we received from the FAB group interview and the internal survey resulted in many problem spaces with lots of overlap.  To more clearly prioritize how to solve the problems, I held a workshop to define and align our user experience goals for our product with the Product Director, Product Lead, and another designer on my team."
                           img=<LightBoxComponent image={array.slice(0,2)}/>
                           imgAlt="UX Priorities"
                           block="contentType1 contentBlock2"
            />

            <ContentType1 title="Mapping the Problem Spaces"
                           content="Once we outlined the UX Priorities for our product, I was able to map the problem spaces from our qualitative feedback to our UX priorities, the UX priorities back to the 2021 product goals, and finally our 2021 product goals back to the 2021 company goal."
                           img=<LightBoxComponent image={array.slice(2,3)}/>
                           imgAlt="Product Discovery Mapping Board"
                           block="contentType1 contentBlock2"
            />

            <ContenType2 sectionTitle=""
                         sectionNumber=""
                         titleBarId=""
                         contentTitle1="Looking Into Campaign Management"
                         content1="“Persado is hard to use” was a repeated sentiment that reverberated from clients to Persado in every form of feedback over the previous year. Our internal teams echoed the same sentiment in the survey, group interviews, and follow up interviews. Along with the idea that we add overhead to every process, Managing large volumes of campaigns within our application was also deemed to be a challenge. With large enterprise clients who run vast amounts of campaigns with Persado, this could prove costly as clients scale with us."
                         block="contentBlock1 contentType2"
            />

            <ContentType1 title="The Initial Problem"
                           content="The majority of this problem space is relating back to the day-to-day user. One of the biggest challenges when working with Persado is managing tasks; what they need to do in order for things to move forward. Many clients use external excel sheets to plan and manage their campaigns and require Persado to work within that structure. There is a definite need to clearly understand what part of the process certain campaigns are in and to be able to plan for the future."
                           img=<LightBoxComponent image={array.slice(2,3)}/>
                           imgAlt="Problem Definition"
                           block="contentType1 contentBlock2"
            />



            <ContentType1 title="Defining the User"
                           content={userProfile}
                           img=<LightBoxComponent image={array.slice(3,4)}/>
                           imgAlt="Product Discovery Mapping Board"
                           block="contentType1 contentBlock2"
            />

          </section>

          <section style={style} class="contentBlock3">

            <ContenType2 sectionTitle="Initial Discovery"
                         sectionNumber="03"
                         titleBarId="initDiscovery"
                         contentTitle1="Compiling what we know"
                         content1="My first step into understanding this problem a little better was to compile a list of research and features ideas that we already know.  In doing this, I found that there were multiple statements and feature requests surrounding our current “experiment list page;” most of which were asking for a clear succinct view of everything that is running with Persado along with their corresponding status. I found this interesting because the page we currently had within the application could actually do just about everything they were asking for."
                         contentTitle2="First Client Interview"
                         content2="After compiling what we know, the product lead and I scheduled our initial interview with a client to understand how they manage campaigns when Persado is and isn’t involved so that we can design Portal (our application) to accommodate their workflow and reduce operational overhead.This call was a 45 min interview with 3 members from one of the larger clients Persado had at the time. After synthesizing the data, it became clear that the client had no direct management system or specific tools they used other than a simple to-do list."
                         block="contentBlock3 contentType2"
              />


            <ContentType1 title="Cross-functional Brainstorming"
                           content="At this point we received multiple links to google sheets created by our internal teams to solve for this; dubbed the “client trackers.” I held a cross-functional brainstorming session consisting of the Product lead, myself, a Product Marketing Manager, and a Customer Success Manager with the intention of identifying potential questions, possible directions, and goals surrounding the use of these “client trackers."
                           img=<LightBoxComponent image={array.slice(5,6)}/>
                           imgAlt="outcome from brainstorm"
                           block="contentType1 contentBlock2"
            />

          </section>

          <section style={style} class="contentBlock4">
            <TitleBar sectionTitle="The root problem"
                      sectionNumber="05"/>
            <ContentType1 title="Too much flexibility? "
                           content="The idea that with greater flexibility, we might add more cognitive load to the user making the application possibly less intuitive and even less useful really stuck with me; I wanted to get an outside perspective about this. I reached out to someone I knew personally and conducted a quick field interview with them about an experience they’ve had with a tool that gave them too many options.  The findings were strikingly similar to the complaints we’ve heard from Persado’s clients."
                           img=<LightBoxComponent image={array.slice(6,7)}/>
                           imgAlt={array.alt}
                           block="contentType1 contentBlock4"
            />


            <ContenType2 sectionTitle=""
                         sectionNumber=""
                         titleBarId=""
                         contentTitle1="Client vs Persado"
                         content1="Our goal for the application, since the beginning, has been to make things simple, straightforward, and easy to follow for the client.  Because we had more access to our internal team than actual client users, we got sidetracked along the way.  Our application devoted the bulk of its resources toward account status and operations rather than helping to answer a simple question from the day to day client user: 'wait, what do you need from me?'"
                         contentTitle2="Constraints"
                         content2="Each company’s org structure is different which causes them to function differently.  Consequently, every planning process for each client is different.  In order to design solution that could be used by everyone, we would need to step away from adding granular specificity to whatever we build."
                         block="contentBlock4 contentType2"
            />



            <ContentType1 title="Getting to the Root of the problem"
                           content="The Product lead and I conducted another brainstorming session to get to the heart of the problems we were seeing from the discovery. Using the method called “the 5 whys,” we conducted two rounds of brainstorming surrounding 2 problems:  Clients have a hard time understanding what they need to do for a Persado Experiment to move forward, and I cannot effectively prioritize campaigns in the application. The root problem was: We try to do too much, and we’re too flexible therefore we can’t automate.  Without automation it takes a lot more effort to run a campaign with Persado."

                           img=<LightBoxComponent image={array.slice(7,8)}/>
                           imgAlt={array.alt}
                           block="contentType1 contentBlock4"
            />

            <ContentType1 title="We are too flexible and we try to do too much."
                           content={flexibilityProblems}
                           img=<LightBoxComponent image={array.slice(3,4)}/>

                           block="contentType1 contentBlock3"
            />
          </section>

          <section style={style}>
            <TitleBar sectionTitle="Redfining the Problem Space"
                      sectionNumber="06"/>

            <ContentType1 title="Initial Experiment Canvas"
                           content="Our discovery led us in a direction that, in hindsight, seemed inevitable. There was constant discussion about having more clearly defined user roles within the application, but creating a separate application for clients only was never a part of the conversation; until this moment. This was our re-defined / official problem space."
                           img=<LightBoxComponent image={array.slice(8,9)}/>
                           imgAlt={array.alt}
                           block="contentType1 contentBlock2"
            />


            <ContenType2 sectionTitle=""
                         sectionNumber=""
                         titleBarId=""
                         contentTitle1="Company Goal"
                         content1="Improve the end-to-end customer experience with Persado and lay the foundation for increased engagement and advocacy within our community of customers."
                         contentTitle2="Product Goal"
                         content2="Scale by Process, Customer Focus"
                         block="goals contentBlock4 contentType2"
            />


          </section>

          {/*<section style={style}>
            <TitleBar sectionTitle="Defining User Goals and Page Goals"/>
            <ul>
              <li>Outlining the Flows / Goals</li>
              <li>Creating initial architecture</li>
            </ul>
          </section>

          <section style={style}>
            <TitleBar sectionTitle="researching the Solution"/>
            <ul>
              <li>Card Sorting</li>
              <li>Prototype 1</li>
              <li>Prototype 2</li>
            </ul>
          </section>

          <section style={style}>
            <TitleBar sectionTitle="MVP Final UI"/>
          </section>*/}






      </article>
    )
  }
}

export default CS3Content;

import React, { Component } from 'react';



// Components
import {Header} from './header.js';
import {Summary} from './summary.js';
import BasicContent from './basicContent.js';
import ContentType1 from './contentType1.js';
import ContenType2 from './contentType2.js';
import ContentType3 from './contentType3.js';
import TitleBar from './titleBar.js';
import Gallery from 'react-grid-gallery';
import ImgCarousel from './carousel.js';
import LightBoxComponent from './imageLightBox.js';


// IMAGES
import CS1_1 from './images/CS1 -- 2.png';
import CS1_2 from './images/CS1 -- 3.png';
import CS1_3 from './images/CS1 -- 4.jpg';
import CS1_4 from './images/CS1 -- 1.1.png';
import CS1_5 from './images/CS1 -- 5.gif';
import CS1_6 from './images/CS1 -- 6.1.png';
import CS1_7 from './images/CS1 -- 6.2.png';
import CS1_8 from './images/CS1 -- 1.png';

const array = [{src:CS1_1, alt:'Current User Flow', video:"false"},
               {src:CS1_2, alt:'Specific Requirements', video:"false"},
               {src:CS1_3, alt:'Initial sketches', video:"false"},
               {src:CS1_4, alt:'Wireframe Sketch', video:"false"},
               {src:CS1_5, alt:'Comments Modal concept', video:"false"},
               {src:CS1_6, alt:'Navigation Re-design 1', video:"false"},
               {src:CS1_7, alt:'Navigation Re-design 2', video:"false"},
               {src:CS1_8, alt:'Final UI Design', video:"false"},
              ];


const titleCopy = () =>{
  return(<h1>Re-thinking Collaboration <br/> With Clients Through <br/> Persado's Desktop Web App</h1>)
};



// Actual Component


class CS1Content extends Component {

constructor(props){
  super(props);


}


componentDidMount(){
  document.addEventListener('scroll', this.scrollFunction)
  console.log(this.headerTitle)
}

componentWillUnmount(){
  document.removeEventListener('scroll', this.scrollFunction)
}

  render() {
    return(
      <section class="CS1_Content1">

          <Summary who={this.props.summary.who}
                   what={this.props.summary.what}
                   result={this.props.summary.result}
                   resultDescription={this.props.summary.resultDescription}

          />
          <ContenType2 sectionTitle="Introduction"
                       sectionNumber="02"
                       contentTitle1="Background"
                       content1="We recognized that each client is different, and therefore each process would be different as well.Because there was no standard practice at the time, it varied from client to client and channel to channel; channel being: email subject line, facebook, landing page, and many more. Some channels required visual elements and others only text.  Some businesses required sign off from the legal department while others were content to push things out immediately."
                       contentTitle2="My Role"
                       content2="As the UX / UI Designer on this project, I collaborated with the Product Manager and multiple Front End Developers. Most of the research for this project was accomplished by the Product Manager before I was brought into the process.   My job was to build on that research, conceptualize a direction, and design the final UI. After the final UI, my job was to give the Front End Developers as much guidance as necessary as they developed."
                       block="contentType2 contentBlock1"
          />

          <TitleBar sectionTitle="Process"
                    sectionNumber="03"
          />

          <ContentType1 title="Understanding The Current Process"
                         content="With so many variables to consider in such varying processes, we needed to find a middle-ground that could encompass the majority of use cases. After some research, I had obtained some insights from project leads and was able to map out a generic journey that would help us understand a generic version of our current collaboration system"
                         img=<LightBoxComponent image={array.slice(0,1)}/>
                         imgAlt="Client Flow"
                         block="contentType1 contentBlock3"
          />
          <ContentType1 title="Speculating Client Pain Points"
                         content="At the time, we didn’t have direct access to clients.  So how do you talk to clients without talking to them?  You gather all the information you can and then make educated assumptions. Based on my conversations with project managers and the map of the current process, I was able to presume a few things:"
                         img=""
                         imgAlt="paint points"
                         block="contentType1 contentBlock4"
          />
          <ContentType1 title="Specific Requirements"
                         content="To create something useful for the first version, this solution would need to include features from other applications that are considered second nature; notifications, history, and saving revisions all.  The product manager and I spoke about some specific requirements to include."
                         img=<LightBoxComponent image={array.slice(1,2)}/>
                         imgAlt="Specific Requirements"
                         block="contentType1 contentBlock5"
          />
          <ContentType1 title="Centralizing All Communications"
                         content="The bulk of communication, up to this point, occurred mainly through very long email chains. These email chains included information pertaining to approvals, revisions, and general notes about specific campaigns or language. The idea was not necessarily to replace email altogether, but rather centralize the back and forth so that our clients could easily approve, ask for revisions, get notified, or see historical documentation in one location. I was inspired by email applications to create a collaboration tool that seemed familiar but also functional.  This layout that would ultimately become the design for the tool."
                         img=<LightBoxComponent image={array.slice(2,4)}/>
                         imgAlt="Specific Requirements"
                         block="contentType1 contentBlock6"
          />

          <ContentType1 title="Making Comments Useful"
                         content="I was concerned that there wasn’t enough screen real-estate for comments to fit on the same page as the deliverables; which would make this useful. So I drafted up a quick concept to illustrate another direction for comments. \n After a few more discussions, we collectively decided that the users needed to be able to see it all on the same screen and that the original concept (comment modal on the right hand side of the screen) would work."
                         img=<LightBoxComponent image={array.slice(4,5)}/>
                         imgAlt="Comments Section"
                         block="contentType1 contentBlock7"
          />

          <ContentType1 title="Making Room for Comments"
                         content="The bulk of communication, up to this point, occurred mainly through very long email chains. These email chains included information pertaining to approvals, revisions, and general notes about specific campaigns or language. The idea was not necessarily to replace email altogether, but rather centralize the back and forth so that our clients could easily approve, ask for revisions, get notified, or see historical documentation in one location. I was inspired by email applications to create a collaboration tool that seemed familiar but also functional.  This layout that would ultimately become the design for the tool."
                         img=<LightBoxComponent image={array.slice(5,7)}/>
                         imgAlt="Comments Section"
                         block="contentType1 contentBlock7"
          />

          <TitleBar sectionTitle="Final UI Design"
                    sectionNumber="04"
          />

          <ContentType1 title="Final UI Design"
                         img=<LightBoxComponent image={array.slice(7,8)}/>
                         imgAlt="Comments Section"
                         block="contentType1 contentBlock9"
          />



      </section>
    )
  }
}

export default CS1Content;

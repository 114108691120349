import React, { Component } from 'react';
import Carousel from './image-carousel2';
import CS2_1 from './images/CS2 -- 1.png';
import CS2_2 from './images/CS2 -- 2.png';
import Carousel_1 from './images/CS2 -- 3.1.png';
import Carousel_2 from './images/CS2 -- 3.2.png';
import Carousel_3 from './images/CS2 -- 3.3.png';
import Carousel_4 from './images/CS2 -- 3.4.png';
import CS2_4 from './images/CS2 -- 4.png';
import CS2_5 from './images/CS2 -- 5.png';
import CS2_6 from './images/Progress Bars (updated).jpg';
import CS2_7 from './images/CS2 -- 6.png';
import CS2_8 from './images/CS2 -- 7.png';

//components
import Gallery from 'react-grid-gallery';


var CS2_1Image =
[{
      src: CS2_1,
      thumbnail: CS2_1,
      thumbnailWidth: 360,
      thumbnailHeight: 400,
      caption: "sketch wireframe"
}];

var CS2_4Image =
[{
      src: CS2_4,
      thumbnail: CS2_4,
      thumbnailWidth: 360,
      thumbnailHeight: 400,
      caption: "sketch wireframe"
}];

var CS2_5Image =
[{
      src: CS2_5,
      thumbnail: CS2_5,
      thumbnailWidth: 360,
      thumbnailHeight: 400,
      caption: "sketch wireframe"
}];

var CS2_6Image =
[{
      src: CS2_6,
      thumbnail: CS2_6,
      thumbnailWidth: 360,
      thumbnailHeight: 400,
      caption: "sketch wireframe"
}];

var CS2_7Image =
[{
      src: CS2_7,
      thumbnail: CS2_7,
      thumbnailWidth: 360,
      thumbnailHeight: 400,
      caption: "sketch wireframe"
}];

var CS2_8Image =
[{
      src: CS2_8,
      thumbnail: CS2_8,
      thumbnailWidth: 360,
      thumbnailHeight: 400,
      caption: "sketch wireframe"
}];

class CS2Content extends Component {




  render() {

    return (

      <div className="content--container1">

        <div id='p1' className="paragraph1">
          <h4>Summary</h4>
          <p id="p1" >The role navigation plays in supporting the value proposition of a company is often times underrated.  Successful Navigation can be the difference between churning or upselling an account; if it’s not clearly understood they’ll leave.
          </p>
          <p>In a self-service application, clients should not have to rely on Account Management teams to walk them through the process. The navigation of the application should provide all of the information they need to move forward, or backward, and achieve the desired results that obtain the value they’re paying for.  In this case, the different options in the navigation were represented by icons that became increasingly difficult to follow and, at times, seemed counter-intuitive.  With this, the clients increasingly stated that they were unsure of the next step.  We later discovered that they were “falling off” later in the process without completing it; the full value of Persado was not being used.
          </p>
        </div>
        <div id='p2' className="paragraph2">
          <h4>What Challenges Were We Solving?</h4>
          <p>From a business perspective, Persado wanted to ensure that clients were using all that we had to offer.  In doing so, Account Managers wouldn’t be spending time “hand holding” our clients through the process while receiving the full value of the product.  Additionally, we discovered that the navigation was confusing our Product team as well.
          </p>
        </div>
        <div id='p3'className="paragraph3">
          <h4>Who Is The End User?</h4>
          <p>The initial discovery surrounding this feature revealed three types of users:  external client user, internal Account Managers, and internal Product users.  Persado Go Email is self service application and therefore the majority of emphasis was placed on solving for the client.  By Solving for the client, we would indirectly be able to solve for the other two.
          </p>
        </div>
        <div id='p4' className="paragraph4">
          <h4>Project Scope and Success Metrics</h4>
          <p>A successful design would bring clarity to the navigation and be easier to follow than the current solution in place. Due to low bandwidth on both the design and development teams, the UX Director and I had initially planned to do a pass on the interface only. We later expanded the project into more detail.
          </p>
        </div>
        <div id='p5' className="paragraph5">
          <h4>Constraints</h4>
          <p>Unfortunately the problem was not as simple as an Interface design challenge. At the time, icons had many different states that would correspond to various phases of the process.
             This was mapped to the “Experimental Design” Process that Persado uses, and it created incredibly complicated logic on the back end which needed to be mapped out in order to move forward.
          </p>
        </div>
        <div className="Quote">
          <h3 className="Quote-1">"</h3>
          <h5>We had to map out the logic in the back-end in order to draft a new front-end solution.
          </h5>
          <h3 className="Quote-3">"</h3>
        </div>
        <div id='p6'className="paragraph6">
          <h4>Approach</h4>
          <ol>
            <li>
              <h4>Catalog the Current Functionality:</h4>
              <p></p>
              <Gallery images={CS2_1Image}
                       backdropClosesModal={true}
                       enableImageSelection={false}
                       rowHeight="700"
                       id="CS2_1"
                       />
            </li>
            <li>
              <h4>Pain Points and Assumptions:</h4>
              <p>Since our access to the clients was limited, Product relied heavily on client feedback from Account Managers.  Account Managers were generally able to relay the sentiments of the client because they worked very closely with them on a daily basis. The product manager for this application had already verified a few pain points before the redesign was pitched to the design team.
              </p>
              <ul>
                <li>Client's don't know when a phase is complete.</li>
                <li>Client's don't have a clear path to see what's next.</li>
                <li>Client's don't know what the icons mean.</li>
              </ul>
              <p>With these pain points already clear, we were able to move quickly to ideation. </p>
            </li>
            <li>
              <h4>Whiteboarding:</h4>
              <p>While trying to understand how to best solve this challenge, the UX Director and I held a whiteboarding session to discuss a few directions.  On a surface level, this seemed to be a User Interface design challenge and the solutions discussed seemed to revolve heavily around redesigning the icons.
              </p>

                <Carousel img1={CS2_2}
                          img2={Carousel_1}
                          img3={Carousel_2}
                          img4={Carousel_3}
                          img5={Carousel_4}
                                />
            </li>
            <li>
              <h4>Gaining a Deeper Understanding:</h4>
              <p>After discussing the initial concepts, We realized that these concepts were actually creating the same problem in a different capacity. As the application matured the process throughout the application gradually became blurred.  We had four legitimate phases in the process but only attempted to show three of them.  To compound the issue, our navigation was only representing the page you’re on and not necessarily the phase of the process you’re in.
              </p>
              <p>Trying to represent both in the navigation was nearly impossible without confusion.  After some thinking, I realized that the most important piece of information to the client is the phase of the process.   If the phases are clearly marked, knowing exactly what page you’re on will take care of itself or will then become irrelevant.
              </p>
              <div className="Quote">
                <h3 className="Quote-1">"</h3>
                <h5>We had to map out the logic in the back-end in order to draft a new front-end solution.
                </h5>
                <h3 className="Quote-3">"</h3>
              </div>
            </li>
            <li>
              <h4>Putting it All Together:</h4>
              <p>When working with enterprise applications, it is often hard to design innovative solutions to what already exists. Our first instinct was to repurpose the icons. However, using solid or filled icons of different colors to denote multiple phases was unclear.  To try something new, I created a 4 phase progress bar to replace the icons.
              </p>
              <Gallery images={CS2_4Image}
                       backdropClosesModal={true}
                       enableImageSelection={false}
                       rowHeight="700"
                       id="CS2_4"
                       />
              <p>This was definitely progress. A progress bar clearly shows a beginning, middle, and end of a process.   This was exactly what we needed! With a new solution in place, a new question began to surface: how would you know what to do  next? We needed to turn this into a call to action so our clients wouldn’t have to think about what to do next; we would tell them.
              </p>
              <Gallery images={CS2_5Image}
                       backdropClosesModal={true}
                       enableImageSelection={false}
                       rowHeight="700"
                       id="CS2_5"
                       />
                       <Gallery images={CS2_6Image}
                                backdropClosesModal={true}
                                enableImageSelection={false}
                                rowHeight="700"
                                id="CS2_6"
                                />
             <p>This solution seemed to solve all but one paint point that was discussed, “Client's don't have a clear path to see what's next.”  While the dashboard now had a solution for this, the actual process within the application still needed to be updated.   We needed to define each phase so that we would know what should be represented in the navigation.
             </p>
             <Gallery images={CS2_7Image}
                      backdropClosesModal={true}
                      enableImageSelection={false}
                      rowHeight="700"
                      id="CS2_7"
                      />
            <p>Since phase 1 actually had 3 steps we not only had to differentiate between active and inactive phases on a macro level but also on a micro level within phase 1 (containing: Basics, Setup, and Content).
            </p>
            <Gallery images={CS2_8Image}
                     backdropClosesModal={true}
                     enableImageSelection={false}
                     rowHeight="700"
                     id="CS2_8"
                     />
            </li>
          </ol>
          <div id='p7' className="paragraph7">
            <h4>Was This Successful?</h4>
            <p>Given the constraints, we definitely succeeded. After having implemented this solution,  we received feedback from Account Managers that users were no longer as confused and this was definitely a step forward in the right direction.  This navigation design became the stepping stone for a few more iterations that helped guide the application into a better, more user friendly, space.
            </p>
          </div>

          </div>

      </div>
    );
  }
}

export default CS2Content;

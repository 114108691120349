import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import HP3 from './images/HP3.png';



class BriefImage3 extends Component {



  render() {

    return (
      <div className="container-brief3">
        <div className="img--container3">
          <img style={{visibility:'hidden'}}className="image-spliter" alt="Case Study 3" src={HP3}/>
        </div>
        <div className="container3 clearfix">
          <h3 className="">Coming Soon</h3>
        </div>

      </div>
    );
  }
}

export default BriefImage3;

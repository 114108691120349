import React, { Component } from 'react';
import {Link} from 'react-router-dom';


//images
import CoverImg from './images/computer.jpg';

//icons
import LockClosed from './lockClosed.js';
import LockOpen from './lockOpen.js';





class BriefImage1 extends Component {




  render() {

    return (
      <div className="container-brief1" key="container-brief1">
        <div className="img--container1" key="img--container1">
          <img className="image-spliter" alt="Case Study 1" src={CoverImg}/>
        </div>
        <div className="container1 clearfix" key="container1">
          <h3 className="" key="container1-h3">Collaboration Center</h3>
          <Link  key="container1-link" to='/Case_Study1'>
            <button className="purple-btn">
              {sessionStorage.getItem("user") ? <LockOpen/> : <LockClosed/>}
                Read the Case Study
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default BriefImage1;

import React, { Component } from 'react';
import {Link, useHistory} from 'react-router-dom';
import LockClosed from '../caseStudies/lockClosed.js';
import Cancel from '../caseStudies/cancel.js';
import {Consumer} from '../../context.js';

//framer motion
import {motion, AnimatePresence, useMotionValue, useViewportScroll,  AnimateSharedLayout, useTransform } from 'framer-motion';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {
  withRouter,
} from 'react-router-dom';

var firebaseConfig = {
    apiKey: "AIzaSyBni4l-PO8lK0zM9hgB5V4mde7c7uMo4XI",
    authDomain: "my-portfolio-11f9d.firebaseapp.com",
    databaseURL: "https://my-portfolio-11f9d.firebaseio.com",
    projectId: "my-portfolio-11f9d",
    storageBucket: "my-portfolio-11f9d.appspot.com",
    messagingSenderId: "544715364609",
    appId: "1:544715364609:web:6f9fd12fbb66ec93",
    measurementId: "G-D1Q9XL6Q99"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

const auth = firebase.auth();






class Login2 extends Component {

  constructor(props){
    super(props);
    this.state = {
      disabled: true,
      isVisible:false,
      buttonText:'Login',
      showForm:false,
    }
    this.isValid = this.isValid.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }



  handleClick = async (event) => {

      try{

          //get the user info
          const email = document.querySelector('#loginEmail').value;
          const password = document.querySelector('#loginPassword').value;

          const scrollPos = window.pageYOffset || document.documentElement.scrollTop;



          await auth.signInWithEmailAndPassword(email, password).then(cred=>{
            console.log(cred.user)

              //close login window and reset the form
              const loginForm = document.querySelector('#login-form');
              loginForm.reset();




              //redirect //
              firebase.auth().onAuthStateChanged(user => {

              if(user) {

                this.props.click();
                this.props.history.replace({pathname:`/modal/${this.props.id}`, state:{id:this.props.id}})


                this.setState({buttonText:'Loading...'})
                this.setState({disabled:true})

              }
            });
            })
              .catch(err => {
                this.setState({isVisible:true})
              })
            } catch(e){this.setState({isVisible:true}); }

  }



  submitform = (e) =>{
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop;
    localStorage.setItem('prevScrollPos', scrollPos);
    this.handleClick();
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  isValid(){
    let loginEmail= document.getElementById('loginEmail')
    let loginPassword = document.getElementById('loginPassword')
    const isValidEmail = loginEmail.checkValidity();
    const isValidPass = loginPassword.checkValidity();
      if(isValidEmail){
        this.setState({isVisible:false})
        console.log('valid')
      }
      if(isValidEmail && isValidPass){
        this.setState({disabled: false})
      }else {
        this.setState({disabled: true})
      }
  }







  componentDidMount() {
    console.log(this.mediaQuery1)
    }

    render(){

      return(
      <Consumer>
      {context => (
        <AnimateSharedLayout exitBeforeEnter>
          <div id="modal-login" className="modal">
            <div className="modal-content">
              {this.state.isVisible ? <p className="errorMsg">Oops, Your username or password may be incorrect</p> : null}
              {!this.state.showForm ? <motion.section layout className="showForm"  transition={{duration:6, ease: "easeInOut"}}>
                                          <motion.button whileHover={{scale:1.05}} layout button initial={{opacity:1}} exit={{opacity:0}} data-showform={this.state.showForm} className="showFormBtn purple-btn" onClick={() => {this.setState({showForm:true})}}>
                                              {!this.state.showForm && <section><LockClosed bgColor="white"/></section>}
                                              Read the Case Study
                                          </motion.button>
                                      </motion.section>
                :

              <motion.form layout id="login-form"
                           data-showForm={this.state.showForm}
                           initial={window.matchMedia('(max-width: 940px)').matches ? {x:-50, opacity:0} : {x:50, opacity:0} }
                           animate={window.matchMedia('(max-width: 940px)').matches ? {x:0,opacity:1} : {x:25,opacity:1}} exit={{x:100,opacity:0, duration:.5,}}
                           transition={{duration:.1, ease: "easeInOut"}}
                           >
                <motion.div className="input-field1">

                  <motion.input onKeyUp={this.isValid}
                         type="email"
                         id="loginEmail"
                         placeholder="Username"
                         required />
                </motion.div>
                <motion.div className="input-field2">

                  <motion.input onKeyUp={this.isValid}
                         type="password"
                         id="loginPassword"
                         placeholder="Password"
                         required />
                </motion.div>
                <motion.input whileHover={!this.state.disabled ? { scale:1.05} : null} disabled={this.state.disabled}
                      id="submitButton"
                      type="button"
                      className="purple-btn"
                      onClick={this.submitform}
                      value= {this.state.buttonText}/>


                <motion.a whileHover={window.matchMedia('(max-width: 768px)').matches ? {scale:1.05} : {rotate:90}}
                          className="backLink"
                          onClick={()=>{return this.setState({showForm:false}), this.setState({disabled: true})}}>

                          {window.matchMedia('(max-width: 768px)').matches ? "Cancel" : <Cancel/>}

                </motion.a>
              </motion.form>

            }
            </div>
          </div>
          </AnimateSharedLayout>
        )}
        </Consumer>
      )
    }

}

export default withRouter(Login2);

import React, { Component } from 'react';
import {motion, usePresence, AnimatePresence, useMotionValue} from 'framer-motion';
import {withRouter,Link, useLocation} from 'react-router-dom';
import {Consumer} from '../../context.js';


import BriefImage1 from './brief_image1.js';
import BriefImage2 from './brief_image2.js';
import BriefImage3 from './brief_image3.js';
import Hero from './Hero.js';
import HP2 from './images/HP2.svg';
import HP3 from './images/HP2.svg';

///components
import Tile from '../csTile.js';
import CS1Content from '../caseStudies/CS1Content.js';
import CS2Content from '../caseStudies/CS2Content.js';
import CS3Content from '../caseStudies/CS3Content.js';



//===================================
// IDs for case studies
//===================================
const id = 1;


//===================================
// Case Study 1
//===================================

// header
const headerTitle = <h1>Re-thinking Collaboration <br/> With Clients Through <br/> Persado's Desktop Web App</h1>;

//Summary
const summary = {who:"Persado",
                 what: "Workflow Improvement,Research, UI Design",
                 result: "Increased Operational Efficiency",
                 resultDescription: "This app Increased Persado’s scalability by designing a tool that would house client logistics( deliverables,  feedback, and revisions) in one application.  Upon its release, the Client Portal increased internal operational efficiency and provided the opportunity to scale while delivering more value to our clients."
               }

//content
const content = <CS1Content summary={summary}/>



//===================================
// Case Study 2
//===================================

// header
const headerTitle2 = <h1>Re-imagining insights with <br/> Persado’s Desktop web App</h1>;

//Summary
const summary2 = {who:"Persado",
                  what: "Research and Discovery, UX Design, UI Design",
                  result: "Increased client engagement",
                  resultDescription: "This tool helped our internal teams centralize and track questions and insights on an ongoing basis while highlighting opportunities for experimentation and boosting consumption as the result."}

//content
const content2 = <CS2Content summary={summary2}/>


//===================================
// Case Study 3
//===================================

// header
const headerTitle3 = <h1>How to Simplify <br/> Persado's Desktop Web App</h1>;

//Summary
const summary3 = {who:"Persado",
                  what: "Research and Discovery, UX Strategy",
                  result: "Client Centered Re-Design of the Application",
                  resultDescription: "This discovery work sparked an initiative that ultimately began to re-define Persado's desktop web application and led to a client centered re-design to help increase acquisition and improve the overall experience of our users."}

//content
const content3 = <CS3Content summary={summary3}/>




//===================================
// Homepage Component
//===================================

class Homepage extends Component {


  componentDidMount(){
    window.scrollTo(0,0);

    if(window.location.pathname != "/"){
      document.getElementById('topSection').style.visibility = "hidden"
    }
  };


  render() {

    const caseStudyTiles = [

      //Case Study 1 object
       {id:id,
        isOpen:this.props.isOpen,
        isAuth:this.props.isAuth,
        locked:true,
        headerTitle:headerTitle,
        summary:summary,
        content:content,
        bgColor:"#3dbd5d",
        order:3,
       },

       ///Case Study 2 object
       {id:id+1,
        isOpen:this.props.isOpen,
        isAuth:this.props.isAuth,
        locked:true,
        headerTitle:headerTitle2,
        summary:summary2,
        content:content2,
        bgColor:"#fbae17",
        order:2,
       },

       ///Case Study 3 object
       {id:id+2,
        isOpen:this.props.isOpen,
        isAuth:this.props.isAuth,
        locked:true,
        headerTitle:headerTitle3,
        summary:summary3,
        content:content3,
        bgColor:"#dd6464",
        order:1,
       },

     ]

    return (
        <Consumer>
        {context => (
          <motion.section  id="home" className="grid-container--Main" >

            <section id="topSection" className="topSection"
                     data-isopen={context.state}>

                <Hero/>

                <section className="quote">
                  <aside>
                    <p>
                      "Brian always asks the tough questions, challenging us to re-think our product daily based on thorough discovery of the customer's problems instead of snap judgements about what features we should prioritize. He is a dedicated, responsible, team player and a true creative mind - a great asset to any project."
                    </p>
                    <h3>- Angel Jin, Product Manager at Persado</h3>
                  </aside>

                  <aside>
                    <p>
                      “Working alongside Brian has been one of the best experiences I’ve had working with a peer. Egoless, organized, and reliable are a few characteristics that not only describe him as a person but his temperament as a designer. On the wide spectrum of design expertise, Brian takes initiatives in communicating with developers and designing systems. “
                    </p>
                    <h3>- Bonnie Chen, UX Designer at Venafi</h3>
                  </aside>

                </section>

            </section>

            <div className="Content" style={{display:'flex', flexDirection:'column'}}>

                {caseStudyTiles.map((i, index)=>{
                  return <Tile id={i.id}
                               order={i.order}
                               key={i.id}
                               data-index={index}
                               context={context}
                               isAuth={i.isAuth}
                               locked={i.locked}
                               isOpen={this.isOpen}
                               bgColor={i.bgColor}
                               headerTitle={i.headerTitle}
                               summary={i.summary}
                               content={i.content}/>
                })}

            </div>

          </motion.section>
        )}
          </Consumer>
    );
  }
}



export default withRouter(Homepage);

import React, { Component } from 'react';
import {withRouter,Link, useLocation} from 'react-router-dom';

class Error404 extends Component {
  render(){
    return(
      <div style={{height:'100vh'}}>
        <p style={{marginTop:'25vh'}}>oops it looks like something went wrong</p>
        <Link to="/">Check out the rest of the website</Link>
      </div>
    )
  }
}

export default Error404;

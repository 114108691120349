import React, { Component } from 'react';

const Description = (props,label, description) => {
  return (
    <section className="description">
        <h1 className="label"><strong>{props.label}</strong></h1>
        <h1 className="summary">{props.summary}</h1>
    </section>
  )
}

export const Summary = (props) => {
  return (
    <section className="caseStudySummary">

      <section className="container">
        <section className="sectionTitle">
          <h1>TL;DR</h1>
        </section>

        <section className="who">
          <h3>Who</h3>
          <p>{props.who}</p>
        </section>

        <section className="what">
          <h3>What</h3>
          <p>{props.what}</p>
        </section>

        <section className="result">
          <h3>Result</h3>
          <p>{props.result}</p>
          <p>{props.resultDescription}</p>
        </section>

      </section>

    </section>
  )
}

export default Summary;

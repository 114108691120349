import React, { Component } from 'react';
import Typist from 'react-typist';


var cursorSettings = {
  show:false
}

class Typewriter2 extends Component {

  render() {
    return (
        <div id="container">
          <Typist cursor={cursorSettings}>
              <Typist.Delay ms={500} />
              <h2 className="h2-2">UX / UI</h2>
              <Typist.Backspace count={11} delay={1000} />
              <Typist.Delay ms={500} />
              <h2 className="h2-3">Experiences</h2>

          </Typist>
        </div>

    );
  }
}



export default Typewriter2;

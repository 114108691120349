import React, { Component, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import{NavLink} from 'react-router-dom';
import {withRouter, useLocation, useHistory, useParams, matchPath, Redirect} from 'react-router-dom';
import {motion, AnimatePresence, useMotionValue, useViewportScroll, useElementScroll, AnimateSharedLayout, useTransform } from 'framer-motion';
import LockClosed from './lockClosed.js';
import LockOpen from './lockOpen.js';
import ArrowBack from '../caseStudies/arrowBack.js';
import {Consumer} from '../../context.js';

//images and icons
import MenuIcon from './images/menu-icon.svg';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

//firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';


const Header2 = ({context, close, isOpen, back}) => {

  const location = useLocation();
  const history = useHistory();



  const ref = useRef()
  const { scrollYProgress } = useElementScroll(ref)

  const goback = (e) => {
    history.goBack();

    const prev = localStorage.getItem('prevScrollPos')

    if(prev){
      setTimeout(function () {
            window.scrollTo(0, parseInt(prev));
        },2);
      }

    localStorage.removeItem('previousState')
    sessionStorage.removeItem("user");
    firebase.auth().signOut();
    localStorage.removeItem('prevScrollPos');
    document.querySelector('body').style.overflow = 'visible';


  }



  const showBack = localStorage.getItem('previousState');
  const isActive = matchPath(window.location.pathname, `/modal/:id` );


  useEffect(()=>{
    return console.log(isOpen)
  },[isOpen])

  return (
    <Consumer>
        {context =>(
            <motion.header id="header"
                    className="header"  ref={ref}>
              <motion.section className="nav1">
                <motion.h3 style={{fontSize:"1.5rem", backgroundColor:"white", }}>Brian Ogston</motion.h3>
                {isActive && showBack ? <motion.a  onClick={()=>{context.close();goback()}}><motion.section whileHover={{scale:1.2}}><ArrowBack/></motion.section></motion.a> : null}
                <motion.a href="https://www.linkedin.com/in/brianogston" target="_blank" className="linkedIn" whileHover={{scale:1.2}} style={{borderRight:"none"}}><LinkedInIcon/></motion.a>
              </motion.section>
            </motion.header>
          )}
    </Consumer>
    );
  }


export default Header2;

import React, { Component } from 'react';
import BasicContent from './basicContent.js';
import TitleBar from './titleBar.js';

const ContentType2 = (props, block, contentTitle1, contentTitle2, content1, content2, sectionTitle, sectionNumber, titleBarId) => {
  return(
    <section class={props.block}>
        <TitleBar sectionTitle={props.sectionTitle} sectionNumber={props.sectionNumber} id={props.titleBarId}/>
        <section class="container">
          <BasicContent title={props.contentTitle1} content={props.content1}/>
          <BasicContent title={props.contentTitle2} content={props.content2}/>
        </section>
    </section>
  )
}

export default ContentType2;

import React, { Component } from 'react';
import BasicContent from './basicContent.js';


const TitleBar = (props, sectionTitle, sectionNumber, id) => {
  return(
    <section class="titleBar" name={props.id}>
      <h4>{props.sectionTitle}</h4>
      <h4>{props.sectionNumber}</h4>
    </section>
  )
}

export default TitleBar;

import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {motion, AnimatePresence, useMotionValue, useViewportScroll,  AnimateSharedLayout, useTransform } from 'framer-motion';




//=========================
// Images Component
//=========================
//handles what content the images contain

const Images = ({video, src, alt}) => {

  //=========================
  // State
  //=========================
  const [isLightBox, setLightBox] = useState(false);


  //=========================
  // Styles
  //=========================
  const style = {
    width:"100%",
  };

  const clickedStyle = {
    position:"fixed",
    zIndex: 70000,
    width:"100%",
    height:"100vh",
    margin:0,
    left:0,
    top:0,
    backgroundColor:"rgba(0,0,0,.7)",
    backdropFilter:"blur(7px)",
    webkitBackdropFilter:"blur(7px)",
    display:"flex",
  };

  const clickedImage = {
    width:"65%",
    margin:"0 auto",
    marginTop:"60px",
    alignSelf:"center",
  }

  const image = {
    width:"100%",
    margin:0,
  }


  //=========================
  // Click Handlers
  //=========================
  const openLightBox = () =>{
    setLightBox(true)
  }

  const closeLightBox = () => {
    setLightBox(false)
  }


  //=========================
  // Return Statement
  //=========================
  return(
    <AnimatePresence>

      <section class="ImgLightBox"
                      style={style}
                      onClick={isLightBox ? closeLightBox : openLightBox}>

                <figure class="ImgLightBoxContainer" style={image}>
                  {video === "true" ? <video controls
                                    src={src}
                                    alt={alt}/>
                           : <img src={src}
                                  alt={alt}/>
                  }
                </figure>
      </section>

      {isLightBox ? <motion.section class="ImgLightBoxOpen"
                                    initial={{opacity:0}}
                                    animate={{ opacity:1}}
                                    exit={{opacity:0}}
                                    layout
                                    style={clickedStyle}
                                    onClick={isLightBox ? closeLightBox : openLightBox}>

                <motion.figure class="ImgLightBoxContainer" style={clickedImage}
                                                            initial={{opacity:0, scale:.25}}
                                                            animate={{opacity:1, scale: 1}}
                                                            exit={{opacity:0, scale:.25}} >
                  {video === "true" ? <video controls
                                    src={src}
                                    alt={alt}/>
                           : <img src={src}
                                  alt={alt}/>
                  }
                </motion.figure>
      </motion.section> : null}

    </AnimatePresence>
  )
}



//=========================
// LightBox Component
//=========================
// handles how the images are displayed

const LightBoxComponent = ({image}) =>{

  //=========================
  // Return Statement
  //=========================
  return (image.map((i)=>{return <Images video={i.video}
                                         src={i.src}
                                         alt={i.alt}
                                         key={i+1}/>}))

  LightBoxComponent.propTypes = {
      isLightBox: PropTypes.bool,
      image: PropTypes.array.isRequired,
      video: PropTypes.bool.isRequired,
    }
}


export default LightBoxComponent;

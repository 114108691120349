import React, { Component, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import { motion, AnimatePresence, useMotionValue, useViewportScroll,} from "framer-motion";

///components
import CS1Content from './CS1Content.js';

import Login2 from '../Login/Login2.js';

import * as firebase from 'firebase/app';
import 'firebase/auth';


//Main Content to be shown when the user logs in

function MainPage({headerTitle}){
  const ref= useRef();
  const { scrollYProgress} = useViewportScroll(ref);


  return(

    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} key="CS1" transition={{type: "tween",
                 ease: "anticipate",
                 duration: 0.5}}
                 className="CS1_Content" key="main" >
        <CS1Content headerTitle={headerTitle} key="content"/>
    </motion.div>
  )
}

//actual component

class CS1 extends Component {

  //scroll progress bar at top of page

  handleScroll = (e) => {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
  }




  constructor(props){
    super(props);
    this.state = {
      isAuth: sessionStorage.getItem("user") || false,
    }
    console.log("Case_Study1.js => " + this.state.isAuth);
    this.scrollFunction = this.handleScroll.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0,0);
    window.addEventListener('scroll', this.scrollFunction,false);


  };


  componentWillUnmount(){
    firebase.auth().signOut();
    window.removeEventListener('scroll',this.scrollFunction,false);
  }

  componentWillReceiveProps(){
    console.log(this.props)
  }




  render() {

    const {headerTitle} = this.props;


    return (
        <motion.main>
          <section style={{position: 'fixed',
                       top: 0,
                       zIndex: 1500,
                       width: '100%',
                       backgroundColor: '#f1f1f1'}}
               class="header">
            <section style={{  width: '100%',
                           height: '8px',
                           background: '#ccc'}}
                 class="progress-container">
              <section style={{height: '8px',
                           background: '#B98136',
                           width: '0%'}}
                   class="progress-bar"
                   id="myBar">
              </section>
            </section>
          </section>
          {this.state.isAuth ? <MainPage headerTitle={headerTitle} /> : <Login2/>}
        </ motion.main>
    );
  }
}

export default withRouter(CS1);

import React, { Component } from 'react';

const BasicContent = (props, title, content, block) => {
  return(
    <section class="basicContent">
        <h1>{props.title}</h1>
        <p>{props.content}</p>
    </section>
  )
}

export default BasicContent;

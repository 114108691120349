import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PainPoints from './images/painPoints.jpg'



// Components
import {Header} from './header.js';
import {Summary} from './summary.js';
import BasicContent from './basicContent.js';
import ContentType1 from './contentType1.js';
import ContenType2 from './contentType2.js';
import ContentType3 from './contentType3.js';
import TitleBar from './titleBar.js';
import Gallery from 'react-grid-gallery';
import ImgCarousel from './carousel.js';
import LightBoxComponent from './imageLightBox.js';

// IMAGES
import CS2_1 from './images/CS2 -- Affinity Mapping.png';
import CS2_2 from './images/CS2 -- Persona1.jpg';
import CS2_3 from './images/CS2 -- Persona2.jpg';
import CS2_4 from './images/CS2 --earlyLOMock.png';
import CS2_5 from './images/CS2 -- UserFlow.png';
import CS2_6 from './images/CS2 -- UserFlow__Prototype.mov';
import CS2_7 from './images/CS2-- conversationalDesign.png';
import CS2_8 from './images/CS2 -- FinalUI__1.jpg';
import CS2_9 from './images/CS2 -- FinalUI__2.jpg';
import CS2_10 from './images/CS2 -- FinalUI__3.jpg';
import CS2_11 from './images/CS2 -- FinalUI__4.jpg';

const array = [{src:CS2_1, alt:'Affinity Mapping', video:"false"},
               {src:CS2_2, alt:'Persona 1', video:"false"},
               {src:CS2_3, alt:'Persona 2', video:"false"},
               {src:CS2_4, alt:'Early Concept Mockup', video:"false"},
               {src:CS2_5, alt:'Ideal User Flow', video:"false"},
               {src:CS2_6, alt:'Early Prototype', video:"true"},
               {src:CS2_7, alt:'Early Prototype', video:"false"},
               {src:CS2_8, alt:'FinalUI__1', video:"false"},
               {src:CS2_9, alt:'FinalUI__2', video:"false"},
               {src:CS2_10, alt:'FinalUI__3', video:"false"},
               {src:CS2_11, alt:'FinalUI__4', video:"false"},
              ];





const titleCopy = () =>{
  return(<h1>Re-thinking Collaboration <br/> With Clients Through <br/> Persado's Desktop Web App</h1>)
};

const spacing = {marginBottom:"16px", display:"list-item"};

const weight = {fontWeight:900}


const SurveyData = ({title,
                     block,
                     number1,
                     number2,
                     number3,
                     number4,
                     description1,
                     description2,
                     description3,
                     description4}) =>{

  const surveyData = [
    {number: number1,
     description: description1},

    {number: number2,
     description: description2},

    {number: number3,
     description: description3},

    {number: number4,
     description: description4},
  ];

  return(
    <section className={block}>
      <section className="surveyDataTitle">
        <h1>{title}</h1>
      </section>
      <section className="surveyDataContainer">
        {surveyData.map((i)=>{
          return <section className="surveyData">
            <h1>{i.number}</h1>
            <p>{i.description}</p>
          </section>
        })}
      </section>
    </section>
  )
}



const Hypothesis = () =>{
  return(
    <p class="hypothesis">
      <em>“As a marketing manager at a Big Marketing Corp, I want to get direction on what will make my next campaign(s) successful so that I can improve on the creative process. (I want to know what made my last campaign(s) successful / not successful)”</em>
    </p>
  )
}


const interviewQuestions = <ol>
                              <li style={spacing}>When was the last time a client said they need actionable insights?</li>
                              <li style={spacing}>How can we present that information in a more meaningful way?</li>
                              <li style={spacing}>What examples have clients given of actionable insights? E.g. other anlaytics / data platforms</li>
                              <li style={spacing}>Who on the client side is asking for actionable insights?</li>
                              <li style={spacing}>if you could change on thing in the Portal to make insights actionable, what would it be?</li>
                          </ol>;


const affinityThemes = <ul>
                          <li style={spacing}><b style={weight}>Goal Oriented:</b><br/>Every <em>great</em> insight we found was the result of questions being asked by the client about a campaign and answered by Persado testing."</li>
                          <li style={spacing}><b style={weight}>Scalable:</b><br/>How do clients utilize the information they learned from us in areas that Persado doesn't currently touch.</li>
                          <li style={spacing}><b style={weight}>Segmented:</b><br/>More detailed information about how certain segments performed and why.</li>
                          <li style={spacing}><b style={weight}>Direct and Simple:</b><br/>Our current <em>insights</em> were entirely too complex and not "customer friendly."</li>
                          <li style={spacing}><b style={weight}>Tell a Story:</b><br/>The best insights consisted of a certain story that was tailored to needs the client had specifically addressed.</li>
                       </ul>

const solutionSummary = <ul>
                          <li style={spacing}><b style={weight}>The best insights were contextual</b><br/>to what the client wants to learn.</li>
                          <li style={spacing}><b style={weight}>Clients were already asking specific questions</b><br/>which were being answered contextually during monthly or quarterly business reviews.</li>
                          <li style={spacing}><b style={weight}>Facilitate this existing process</b><br/>within the application and hopefully inspire our clients to ask us more.</li>
                          <li style={spacing}>Giving the client the most value possible with <b style={weight}>insights they can actually understand and apply</b></li>
                       </ul>


const clientFeedback = <ul>
                          <li style={spacing}><b style={weight}>"Love this learning objective feature!! Thanks so much! Very interesting on the gifting for others headline piece - also interesting that "Gifts to Give" was a lower performer considering we have this phrase in a lot of our holiday copy.""</b></li>
                          <li style={spacing}><b style={weight}>"It's a good way to look back on so many different tests and see everything in one place."</b></li>
                          <li style={spacing}><b style={weight}>"We pull implications from Experiment Intelligence into weekly reporting and upcoming creative briefs."</b></li>
                          <li style={spacing}><b style={weight}>"There had been some back and forth about how to proceed, and Experiment Intelligence helped us understand the mixed results and inform the creative strategy, instead of basing off of instinct."</b></li>

                       </ul>


const internalFeedback = <ul>
                          <li style={spacing}><b style={weight}>"They think we built it for them!"</b></li>
                          <li style={spacing}><b style={weight}>"A good way to involve them in the use of Portal."</b></li>
                          <li style={spacing}><b style={weight}>"Lot's of clients ask for this information so it fascilitates and existing process."</b></li>
                          <li style={spacing}><b style={weight}>"We've been asking for actionable insights for a long time and this could be the solution!"</b></li>
                       </ul>

// Actual Component


class CS2Content extends Component {

constructor(props){
  super(props);


}


componentDidMount(){
  document.addEventListener('scroll', this.scrollFunction)
  console.log(this.headerTitle)
}

componentWillUnmount(){
  document.removeEventListener('scroll', this.scrollFunction)
}

  render() {
    return(
      <article class="CS2_Content1">

          <Summary who={this.props.summary.who}
                   what={this.props.summary.what}
                   result={this.props.summary.result}
                   resultDescription={this.props.summary.resultDescription}

          />
          <ContenType2 sectionTitle="Introduction"
                       sectionNumber="02"
                       titleBarId="introduction"
                       contentTitle1="My Role"
                       content1="As the lead UX Designer on the project, I collaborated with the Product Leads, Director UX / UI, and the other UX Designer on my team to conduct research, iterate, and pitch a brand new solution.  During the implementation phase, I worked closely with the Development Leads to ensure usability of the feature."
                       contentTitle2="Background"
                       content2="In May of 2020, Persado’s Product Marketing team in conjunction with Product and Design rolled out a survey to understand client goals and our internal team’s challenges.   We found that the reporting and content insights we were currently giving clients didn’t drive a clear action and thereby causing some issues proving Persado’s value."
                       block="contentBlock1 contentType2"
          />
          <section className="surveys">
            <SurveyData title="Challenges in proving the value of Persado"
                        number1="60.0%"
                        description1="Reporting or insights that don’t drive a clear action"
                        number2="52.5%"
                        description2="Producing insights that are relevant for your clients"
                        number3="50.0%"
                        description3="Generating insights and client deliverables quickly"
                        number4="32.5%"
                        description4="Training clients on Persado’s value proposition and our  data science"
                        block="surveyDataMain one"
            />
            <SurveyData title="What do your clients care most about?"
                        number1="77.5%"
                        description1="Performance"
                        number2="75.0%"
                        description2="Content Insights"
                        number3="50.0%"
                        description3="Audience Reporting / Segmentation"
                        number4="27.5%"
                        description4="Emotions"
                        block="surveyDataMain two"
            />
          </section>



          <ContenType2 sectionTitle="Process"
                       sectionNumber="03"
                       contentTitle1="Define 'actionable'"
                       content1="In defining “actionable,” we first needed to understand what makes information valuable and relevant to the client. How do we make the information we give clients valuable, relevant, and, most importantly, understandable to them?"
                       contentTitle2="Our Hypothesis"
                       content2=<Hypothesis/>
                       block="contentBlock2 contentType2"
          />

          <ContenType2 sectionTitle="Research and Discovery"
                       sectionNumber="03"
                       titleBarId="research"
                       contentTitle1="Initial Interviews"
                       content1="Within the research process, our access to clients was limited which presented an issue when designing a tool specifically for them. In lieu of client access, the team interviewed 21 individuals from varying roles within Persado, most client facing, with the goal of understanding what different clients define as “actionable.” What we found was:"
                       contentTitle2=""
                       content2=" "
                       block="contentBlock3 contentType2"
          />

          <ContentType1 title=" "
                         content={affinityThemes}
                         img=<LightBoxComponent image={array.slice(0,1)}/>
                         block="contentBlock4 contentType1"
          />

          <ContenType2 sectionTitle="Research and Discovery"
                       sectionNumber="03"
                       contentTitle1="Client Personas"
                       content1="With all of our research in hand, I crafted some client personas to more adequately define the challenges presented from the customer's perspective."
                       contentTitle2=" "
                       content2=" "
                       block="contentBlock5 contentType2"
          />

          <section className="contentBlock5 personas">
            <LightBoxComponent image={array.slice(1,3)}/>
          </section>

          <section class="contentBlock6">
            <BasicContent title="What We Learned"
                          content="Each Quarterly Business Review Deck created by Persado’s internal teams reflected a story unique to that specific customer which allowed them to get answers to distinct questions."
            />
          </section>

          <TitleBar sectionTitle="Defining the Problem" sectionNumber="04" titleBarId="problem"/>

          <ContentType1 title="Brainstorming Solutions"
                         content="The team reconvened with the addition of a few representatives from Data Science and held a brainstorming session using a Trello board. In the end, we landed on three converged ideas which we split into teams to explore.  The Product Lead and other UX Designer on the team explored the concept which ultimately became the main feature: Adding goals in the experiment workflow."
                         img=<LightBoxComponent image={array.slice(3,4)}/>
                         block="contentType1 contentBlock7"
          />

          <section class="contentBlock8">
            <BasicContent title="Value in Questions"
                          content="The idea behind this was to be goal oriented.  If we know what the client wants to learn we can craft our experiments and tailor the insights to help answer those questions. Which, in turn, gives them valuable information that is actionable, relevant, and understandable."
            />
          </section>


          <ContenType2 sectionTitle="Research and Discovery"
                       sectionNumber="03"
                       contentTitle1="Problem Summary"
                       content1={solutionSummary}
                       contentTitle2="Problem Statement"
                       content2="How can we encourage clients to ask testable hypothesis-driven questions and empower them to find the answers through Persado testing, so that we can help them find solutions to their business goals?"
                       block="contentBlock9 contentType2"
          />

          <section class="contentBlock10">
            <BasicContent title="Value Proposition"
                          content="Relationships succeed when both parties are getting value from it.  With this goal driven approach, clients would get strategic advice on how to succeed and the Persado teams will no longer have an issue proving the value of the company; it’s a win-win."
            />
          </section>


          <ContenType2 sectionTitle="Designing the Feature"
                       sectionNumber="05"
                       titleBarId="Design"
                       contentTitle1="Rough Prototype and Validation"
                       content1="With the first round of validation interviews, we crafted a prototype that was our idea of a “happy path” which created a natural funnel for the questions and answers to be integrated into the existing process. Overall, the initial feedback was very positive and solidified that this solution was headed in the right direction."
                       contentTitle2=""
                       content2=""
                       block="contentBlock11 contentType2"
          />

          <section className="contentBlock11 prototype">
            <LightBoxComponent image={array.slice(4,6)}/>
          </section>

          <ContenType2 sectionTitle="Final UI"
                       sectionNumber="06"
                       titleBarId="Design"
                       contentTitle1="Final UI"
                       content1="After the initial concept validation, we made some adjustments and started to finalize the UI of the tool."
                       contentTitle2=""
                       content2=""
                       block="contentBlock12 contentType2"
          />

          <section className="contentBlock12 finalUI">
            <section>
              <LightBoxComponent image={array.slice(7,9)}/>
            </section>
            <section>
              <LightBoxComponent image={array.slice(9,11)}/>
            </section>
          </section>


          <ContenType2 sectionTitle="Beta Release Feedback"
                       sectionNumber="06"
                       contentTitle1="Client Feedback"
                       content1={clientFeedback}
                       contentTitle2="Client Feedback"
                       content2="Once we were able to define a list of clients to run the beta with, we were able to present the concept to them as a part of Quarterly Business Review meetings.  The concept was received with glowing reviews:"
                       block="contentBlock13 contentType2"
          />

          <ContenType2 sectionTitle="Beta Release Feedback"
                       sectionNumber="06"
                       contentTitle1="Internal Feedback"
                       content1={internalFeedback}
                       contentTitle2="Internal Feedback"
                       content2="Once we were able to define a list of clients to run the beta with, we were able to present the concept to them as a part of Quarterly Business Review meetings.  The concept was received with glowing reviews:"
                       block="contentBlock14 contentType2"
          />

          <TitleBar sectionTitle="Result" sectionNumber="07" titleBarId="Result"/>

          <ContentType1 title="What did We Accomplish?"
                         content="This tool helped our internal teams centralize and track questions and insights on an ongoing basis while highlighting opportunities for experimentation and boosting consumption as the result."
                         img=<section className="surveys"><SurveyData title="Key Performance Indicators (3/15/2021)"
                                     number1="157"
                                     description1="Total Submitted"
                                     number2="28"
                                     description2="Resolved"
                                     number3="100%"
                                     description3="Acted On"
                                     number4="29"
                                     description4="Number Brands with Feature Flag On (as of 3/15/2021)"
                                     block="surveyDataMain two"
                         /></section>
                         block="contentType1 contentBlock15"
          />


      </article>
    )
  }
}

export default CS2Content;

import React, { Component } from 'react';

export const Header = (props, {title}) => {



  return (
    <section className="caseStudyHeader">

      <section className="number">
        <h1>01</h1>
      </section>

      <section className="title">
        {props.headerTitle}
      </section>

    </section>
  )
}

export default Header;

import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import CoverImg from './images/computer2.jpg';
import LockClosed from './lockClosed.js';
import LockOpen from './lockOpen.js';



class BriefImage2 extends Component {



  render() {

    return (
      <div className="container-brief2">
        <div className="img--container2">
          <img className="image-spliter" alt="Case Study 2" src={CoverImg}/>
        </div>
        <div className="container2 clearfix">
          <h3 className="">Updated Navigation</h3>
          <Link to='/Case_Study2'>
          <button className="purple-btn">
          {sessionStorage.getItem("user") ? <LockOpen/> : <LockClosed/>}
          Read the Case Study</button></Link>
        </div>
      </div>
    );
  }
}

export default BriefImage2;

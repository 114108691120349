import React, { Component } from 'react';
import BasicContent from './basicContent.js';


const ContentType1 = (props, block, title, content,content2,img,imgAlt) => {
  console.log(img)
  return(
    <section class={props.block}>

      <section class="container">

          <BasicContent title={props.title}
                        content={props.content}
          />

          <figure class="image">
            {props.img}
          </figure>

      </section>


    </section>
  )
}

export default ContentType1;

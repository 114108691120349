import React, { Component, useEffect } from 'react';
import './Assets/css/default.min.css';
import {motion, AnimatePresence} from 'framer-motion';
import {Provider, Consumer} from './context.js';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useLocation,
  useParams,
  useHistory,
} from 'react-router-dom';



//components
import Header2 from './Components/header_component/header2';
import Footer from './Components/footer_component/footer';
import Homepage from './Components/homepage/homepagecontent';
import CS1 from './Components/caseStudies/case_study1.js';
import CS2 from './Components/CS2/case_study2.js';
import Login2 from './Components/Login/Login2.js';
import PrivateRoute from './privateRoute.js';
import Error404 from './Components/404/404.js';
import Tile from './Components/csTile.js';
//images

//firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';



const animation = {
    initial: { opacity: 0 },
    active: {
      opacity: 1,
      transition: {
        delay: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    exit: { opacity: 0, y: 200 },
  };




const Routes = ({isAuth,change,open,close,isOpen}) => {

  let location = useLocation();
  let background = location.state && location.state.background;

  useEffect(()=>{
    console.log(isAuth)
  },[isAuth])

  useEffect(()=>{
    if(location.pathname === '/'){return close()}
  },[location])

  return (


      <Route>
        <AnimatePresence  initial={true}>


        <Switch location={ background || location }>

        <Route exact
               path='/'
               render={(props)=>{ return <Homepage {...props} isAuth={isAuth} open={open} isOpen={isOpen}/>}}/>

        <Route exact
               path='/login'
               component={Login2}
               key="login"/>

        <Route
            exact
            path="/login"
            component={Login2}/>

        <Route exact path={["/","/modal/:id"]} children={Homepage}/>

        <Route component={Error404}/>

        </Switch>

        {location && <Route path="/modal/:id"/>}

        </AnimatePresence>
      </Route>

)}





class App extends Component {

  constructor(props) {
  super(props);
  this.state = {
    isAuth: false,
    CS2: '/Case_Study2',
    msg: '',
    isOpen: false,
  };

}



open =()=>{
  this.setState({isOpen:true}, ()=>{console.log(`the state is now + ${this.state.isOpen}`)})


}

close = (e) => {
  this.setState({isOpen:false}, ()=>{return console.log(`the state is now + ${this.state.isOpen}`)})
}


/*componentDidMount() {
  firebase.auth().onAuthStateChanged(() =>{
    var user = firebase.auth().currentUser;
    if(user){
      this.setState({isAuth:true})
      console.log(`the user is logged in, ${this.state.isAuth}`)
      sessionStorage.setItem("user",user)
    } else if(!user){
      firebase.auth().signOut();
      this.setState({isAuth:false});
      this.close();
    }
    console.log(this.state.isAuth)
  })

  if(localStorage.getItem('previousState')){this.setState({isOpen:true}, ()=>{console.log(`the state is now + ${this.state.isOpen}`)})}
}*/







  render() {






    return (



    <Router>
      <div className="App">
      <Provider value={{state:this.state.isOpen,
                        open:this.open,
                        close:this.close,
                      }}>
            <Header2 key="Header" close={this.close} isOpen={this.state.isOpen}/>
      </Provider>

            <div className="RouteContainer1" style={{position:'relative',}}>
            <Provider value={{state:this.state.isOpen,
                              open:this.open,
                              close:this.close,
                            }}>
                <Consumer>
                  {context => (
                    <Routes isAuth={this.state.isAuth} open={this.open} close={this.close} isOpen={context.state}/>
                  )}
                </Consumer>
            </Provider>
            </div>
        <Footer key="Footer"/>



      </div>
    </Router>
    );
  }
}


export default App;

import React, { Component } from 'react';
import CS1Hero from './images/CaseStudyHero.svg';
import HP4 from './images/HP4.png';
import CS_BG1 from './images/CaseStudyBG.svg';
import { withRouter } from 'react-router-dom';
import {motion} from 'framer-motion';


///components
import CS2Content from './Content1.js';
import Login2 from '../Login/Login2.js';





function MainPage(){
  return (
    (
      <motion.div iinitial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} key="CS1" transition={{type: "tween",
                   ease: "anticipate",
                   duration: 0.5}} className="CS2_Content" key="main">
      <div className="CS2-Hero">
        <img className="BG_img" alt="Dot Texture Background" src={CS_BG1}/>
        <img alt="Case Studies" src={CS1Hero}/>
        <h2>Updated Navigation</h2>
        <div className="green-box"></div>
      </div>
        <img className="image-spliter" alt="Final Product" src={HP4}/>
        <CS2Content key="content"/>
    </motion.div>
    )
  );
}


class CS2 extends Component {

  handleScroll = (e) => {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
  }


  constructor(props){
    super(props);
    this.state = {
      isAuth: sessionStorage.getItem("user") || false,
    }
    console.log("Case_Study2.js => " + this.state.isAuth);
    this.scrollFunction = this.handleScroll.bind(this);
  }


  componentDidMount(){
    window.scrollTo(0,0);
    window.addEventListener('scroll', this.scrollFunction,false);
  };

  componentWillUnmount(){
    window.removeEventListener('scroll',this.scrollFunction,false);
  }


  render() {
    return (
      <div>
        <div style={{position: 'fixed',
                     top: 0,
                     zIndex: 1,
                     width: '100%',
                     backgroundColor: '#f1f1f1'}}
             class="header">
          <div style={{  width: '100%',
                         height: '8px',
                         background: '#ccc'}}
               class="progress-container">
            <div style={{height: '8px',
                         background: '#B98136',
                         width: '0%'}}
                 class="progress-bar"
                 id="myBar">
            </div>
          </div>
        </div>
        {this.state.isAuth ? <MainPage/> : <Login2/>}
      </div>
    );
  }
}

export default withRouter(CS2);

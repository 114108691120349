import React from 'react';
import {motion, AnimatePresence, useMotionValue, useViewportScroll,  AnimateSharedLayout, useTransform } from 'framer-motion';


export default ({ExpandArrow, visibility, styles1, showSummary}) => {

  const styles = {
      width: '12px',
      height:'16px',
      visibility:visibility,
  }

  return (
    <motion.svg onClick={{x:90}} height="24" style={showSummary ? styles1 : styles} viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
    </motion.svg>
  )
}

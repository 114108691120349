import React from "react";
import Slider from "react-slick";


class Carousel extends React.Component {
  render() {
    var settings = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:true,
      vertical:false,
      adaptiveHeight:true,
      //autoplay:true,
      //centerMode:true,
      draggable:true,
      infinite:false,
    };
    return (
      <Slider {...settings}>
        <div>
          <img alt={this.props.alt1} src={this.props.img1}/>
        </div>
        <div>
          <img alt={this.props.alt2} src={this.props.img2}/>
        </div>
      </Slider>
    );
  }
}

export default Carousel;

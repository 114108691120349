import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footer-text">
          <p><strong>Copyright 2021 | Brian Ogston</strong></p>
        </div>

      </footer>
    );
  }
}

export default Footer;

import React from 'react';
import Component from 'react';
import props from 'prop-types';
import PropTypes from 'prop-types';

//IMAGES
import CS1_2 from './images/CS1 -- 2.png';
import CS1_3 from './images/CS1 -- 3.png';
import CS1_4 from './images/CS1 -- 4.jpg';


const imgContainer =  document.getElementsByClassName("imgContainer");
let i;
let slideIndex = 1;




//image array






//convert the image array into JSX

const Slides = ({i, imgArr}) => {

  return imgArr.map(image => {

        return <section class="imgContainer">
                    <figure>
                      <img src={image.src} alt={image.alt} key={i}/>
                    </figure>
               </section>}
  )

  Slides.propTypes ={

    images: PropTypes.array

  }

};







//how to display the slides

const showSlides = (n) => {

   let slides = document.getElementsByClassName('imgContainer');

   if(n > slides.length){slideIndex = 1}
   if(n < 1){slideIndex = slides.length}
   for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
      slides[i].classList.remove('active');
  }

  slides[slideIndex-1].style.display = "block";
  slides[slideIndex-1].classList.add('active');
}

//navigation for the buttons.

const plusSlides = (n) => {
  showSlides(slideIndex += n);

  console.log('clicked');
}















class ImgCarousel extends React.Component{


componentDidMount(){
  showSlides(slideIndex);
}

  constructor(props) {
    super(props);



  }


  render(){

    return(
      <section class="imgCarousel">
          <section class="container">
            <button class="sliderBtn prev" onClick={() => plusSlides(-1)}  />

                <Slides {...this.props} />


            <button class="sliderBtn next" onClick={() => plusSlides(1)} />
          </section>
      </section>
    )

  }
}

export default ImgCarousel;

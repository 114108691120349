import React, { Component } from 'react';
import Typewriter2 from './typewriter2.js';

import HeroBG from './images/HP1.svg';

//IMAGES
import ProfileImage from './images/profileImage.png';
import Logos from './images/logos.png';



const Hero = () => {

  return(
    <section className="Hero">

      <section className="heroLeft">
        <section className="borderDiv">
            <figure className="profileImageContainer">
              <img className="profileImage" src={ProfileImage} alt=""/>
            </figure>
        </section>
      </section>

      <section className="heroRight">

            <section className="about1">
              <h3>I'm a UX / UI designer</h3>
              <p>with <strong>6+ years experience</strong> working as an in-house designer for enterprise software.  I have experience using many different design techniques and research methods, within constraints, to define problem spaces, ideate solutions, and design and collaborate with development. My knowledge spans from industry standard applications to a working knowledge of code.  What problems can I help you solve?</p>
            </section>

            <section className="about2">
              <h3>I've done work for</h3>
              <figure>
                <img src={Logos} alt=""/>
              </figure>
            </section>

      </section>


    </section>
  )
}



export default Hero;

import React, { Component,Fragment, useState, useRef, useLayoutEffect, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Link, useLocation, useHistory, useParams, matchPath, Redirect} from 'react-router-dom';
import {motion, AnimatePresence, useMotionValue, useViewportScroll,  AnimateSharedLayout, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';


//firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';

//Components
import CS1 from './caseStudies/case_study1.js';
import CS1Content from './caseStudies/CS1Content.js';
import Login2 from './Login/Login2.js';
import Summary from './caseStudies/summary.js';
import Header from './caseStudies/header.js';
import {Consumer} from '../context.js';
import ExpandArrow from './caseStudies/expandArrow.js';




const Tile = ({key,
               ref,
               bgColor,
               order,
              context,
              isAuth,
              content,
              locked,
              background,
              headerTitle,
              summary,
              id}, i,props) =>{




//===================================
// Component State
//===================================

  // initial State
  let initialState = false;


  //Reducer
  function reducer(isOpen, action) {
    switch (action.type) {
      case 'stateTrue':
        return true;
      case 'stateFalse':
        return false;
      default:
        return false;
    }
  }

  //State handler
  const [isOpen, dispatch] = useReducer(reducer, initialState);
  const [showSummary, setSummary] = useState(false);


  //mediaQuery
  const mql = window.matchMedia('(max-width: 940px)').matches

  //icons
  const expand = <ExpandArrow/>

//===================================
// React Router
//===================================

  //Hooks
  const location = useLocation();
  const history = useHistory();
  const activeTile = useRef();

  //page Id
  const pageId = [];
  pageId.push(id);



//===================================
// Open Modal Function
//===================================


  function toggleOpen(e) {


      context.open();
      dispatch({type:'stateTrue'});
      localStorage.setItem('previousState', true);


      //scroll the header component into view onclick... only works in settimeout?


    }

    function toggleOpen2(e) {

        const scrollPos = window.pageYOffset || document.documentElement.scrollTop;
        localStorage.setItem('prevScrollPos', scrollPos);



        context.open();
        dispatch({type:'stateTrue'});
        localStorage.setItem('previousState', true);


        //scroll the header component into view onclick... only works in settimeout?


      }

    const handleClick = () => {
      if(showSummary){
        return setSummary(false)
      } else{return setSummary(true)}
    }


    const mediaQueryCheck = (value) => {
      if(window.matchMedia(`(max-width: ${value}px)`).matches){
        return setSummary(false)
      }
      else if(!window.matchMedia(`(max-width: ${value}px)`).matches){
        return setSummary(true)
      }

    }




//===================================
// ComponentDidMount
//===================================

  useEffect(() =>{



    const pathArray = window.location.pathname.split('/');
    if(pathArray[2] == pageId && localStorage.getItem('previousState')){return  dispatch({type: 'stateTrue'}) && context.open()}
    else if(pathArray[2] !== pageId && !localStorage.getItem('previousState')){return dispatch({type: 'stateFalse'}) && context.close() }
    else if(context.state === false){return dispatch({type: 'stateFalse'}) && context.close()}
    else if(location.pathname === '/'){dispatch({type: 'stateFalse'}) && context.close()}
    else if(isAuth && location.pathname !== '/'){return context.close(), dispatch({type: 'stateFalse'}), history.push('/') }
    else if(pathArray[2] == pageId){return dispatch({type:'stateTrue'}) && context.open()}
    else if(pathArray[2] !== pageId){return dispatch({type:'stateFalse'}) && context.close()};



  },[context.state, location, isAuth])

  useEffect(()=>{
    if(!localStorage.getItem('previousState')){return history.push('/')};

  },[])



  useEffect(()=>{
     setSummary(false);
     firebase.auth().signOut();
  },[location])













// =================================================
//    Basic Button Component
// =================================================


   const ButtonLink = (e) => {
    return(
      <Consumer>
      {context => (

           !isOpen ? <Link key={key}
              to={{pathname:`/modal/${pageId}`, state: { background: location, isOpen: isOpen, }}}>

              <button className="tileButton1 purple-btn"
                       onClick={() => toggleOpen2() }>

                      Read the Case Study

              </button>
          </Link>
          :
          null
        )}
      </Consumer>

)}


// =================================================
//    Authorizaton Button Component
// =================================================


  const Button = () => {



    return (
      <Consumer>
        {context => (
        <AnimateSharedLayout>

          {!isAuth ? <motion.section ><Login2 click={toggleOpen} location={history} id={pageId}/></motion.section> : <ButtonLink/> }

        </AnimateSharedLayout>
      )}
      </Consumer>

    )
  }



// =================================================
//    Header Components
// =================================================

  const HeaderMain = (props, {title}) => {


      return (
        <Consumer>
        {context => (

            <motion.section>

              <Header headerTitle={headerTitle}/>

              {locked ? !isOpen && <Button/> : <ButtonLink/>}

              </motion.section>

        )}
        </Consumer>
      )
    }


  const TileHeader = () => {

      return (
        <Consumer>
        {context => (
          <motion.section className="titleCopy" layout data-isopen={isOpen}>

              <HeaderMain/>


          </motion.section>
        )}
        </Consumer>

          )
        }


// =================================================
//    mobile Summary
// =================================================

    const MobileSummaryLink = (event) =>{


      const expanded = {
        transform:"rotate(180deg)",
        width: '12px',
        height:'16px',
        visibility:"visibility",
      }

      return(
        <a className="showSummary" onClick={handleClick}>Summary <ExpandArrow showSummary={showSummary} styles1={expanded}/></a>
      )
    }


// =================================================
//    Actual Tile Component Return
// =================================================


  return(
    <Consumer>
    {context => (

      <AnimatePresence exitBeforeEnter>



        <motion.section key={key}
                        id={id}
                        ref={activeTile}
                        className="CsTileContainer"
                        style={{backgroundColor:'white', order:order}}


                        data-isopen={isOpen}
                        transition={{delayChildren:1}} >

            {location && <motion.section id="header"
                                         className="caseStudyHeader"
                                         data-isopen={isOpen}
                                         style={isOpen ? {backgroundColor:bgColor} : {backgroundColor:"#fff"}}
                                         layout={showSummary ? false : true}
                                         transition={{when:"beforeChildren",duration:.1, ease: "easeInOut"}}>


                              <TileHeader/>
                              <Summary who={summary.who} what={summary.what} result={summary.result} resultDescription={summary.resultDescription}/>
                              <section id="mobileSummary">
                                  <MobileSummaryLink/>

                                  {showSummary && <Summary who={summary.who} what={summary.what} result={summary.result} resultDescription={summary.resultDescription}/>}
                              </section>
                         </motion.section>}

            {isOpen ? <motion.section layout>{content}</motion.section> : null}

        </motion.section>

      </AnimatePresence>
    )}
    </Consumer>
  )

}


// =================================================
//   PropTypes
// =================================================

Tile.propTypes = {
  locked: PropTypes.bool,
  isOpen: PropTypes.bool,
};



export default withRouter(Tile);

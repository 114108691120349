import React from 'react';



export default ({arrowBack, visibility}) => {

  const styles = {
      paddingRight:'16px',
      width: '12px',
      height:'16px',
      visibility:visibility,
  }

  return (
    <svg styles={styles} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
    </svg>
  )
}

import React from 'react';

const styles = {
  paddingRight:'16px',
  width: '12px',
  height:'17px',
}



export default ({LockOpen}) => {
  return (
    <svg style={styles} width="16px" height="21px" viewBox="0 0 16 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="lock_open2" transform="translate(-4.000000, -1.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M12,17 C13.1,17 14,16.1 14,15 C14,13.9 13.1,13 12,13 C10.9,13 10,13.9 10,15 C10,16.1 10.9,17 12,17 Z M18,8 L17,8 L17,6 C17,3.24 14.76,1 12,1 C9.24,1 7,3.24 7,6 L8.9,6 C8.9,4.29 10.29,2.9 12,2.9 C13.71,2.9 15.1,4.29 15.1,6 L15.1,8 L6,8 C4.9,8 4,8.9 4,10 L4,20 C4,21.1 4.9,22 6,22 L18,22 C19.1,22 20,21.1 20,20 L20,10 C20,8.9 19.1,8 18,8 Z M18,20 L6,20 L6,10 L18,10 L18,20 Z" id="Shape" fill-rule="nonzero"></path>
            </g>
        </g>
    </svg>
  )
}
